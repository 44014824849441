import React, { useEffect, useRef } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  HStack,
  Text,
} from '@chakra-ui/react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const ConciergeFormInputs = ({
  formData,
  handleChange,
  handlePharmacySelect,
  handleAddressSelect,
}) => {
  return (
    <>
      {/* First Name */}
      <FormControl isRequired>
        <FormLabel>First Name</FormLabel>
        <Input
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />
      </FormControl>

      {/* Last Name */}
      <FormControl isRequired>
        <FormLabel>Last Name</FormLabel>
        <Input
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />
      </FormControl>

      {/* Date of Birth */}
      <FormControl isRequired>
        <FormLabel>Date of Birth</FormLabel>
        <Input
          type="date"
          name="dob"
          value={formData.dob}
          onChange={handleChange}
        />
      </FormControl>

      {/* Primary Phone */}
      <FormControl isRequired>
        <FormLabel>Primary Phone</FormLabel>
        <Input
          type="tel"
          name="primaryPhone"
          value={formData.primaryPhone}
          onChange={handleChange}
        />
      </FormControl>

      {/* Email */}
      <FormControl isRequired>
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </FormControl>

      {/* Address (Combined with City, State, Zip Code) */}
      <FormControl isRequired>
        <FormLabel>Address</FormLabel>
        <Text fontSize="sm" color="gray.500" mb={2}>
          Type your street address below to search and select it from the list.
        </Text>
        <GooglePlacesAutocomplete
          apiKey="AIzaSyBxp-EpGRK9JakC2C0bkayZcjREP24RfLI"
          selectProps={{
            placeholder: 'Type your address',
            onChange: handleAddressSelect,
          }}
        />
      </FormControl>

      {/* Emergency Contact Name and Phone */}
      <HStack spacing={4}>
        <FormControl>
          <FormLabel>Emergency Contact Name (First & Last)</FormLabel>
          <Input
            name="emergencyContactName"
            value={formData.emergencyContactName}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Emergency Contact Phone</FormLabel>
          <Input
            type="tel"
            name="emergencyContactPhone"
            value={formData.emergencyContactPhone}
            onChange={handleChange}
          />
        </FormControl>
      </HStack>

      {/* Pharmacy Search */}
      <FormControl isRequired>
        <FormLabel>Pharmacy</FormLabel>
        <Text fontSize="sm" color="gray.500" mb={2}>
          Type your pharmacy name below to search and select it from the list.
        </Text>
        <GooglePlacesAutocomplete
          apiKey="AIzaSyBxp-EpGRK9JakC2C0bkayZcjREP24RfLI"
          selectProps={{
            onChange: handlePharmacySelect,
          }}
        />
      </FormControl>

      {/* Pharmacy Name and Phone */}
      {/**<HStack spacing={4}>
      
        <FormControl isRequired>
          <FormLabel>Pharmacy Phone</FormLabel>
          <Input
            type="tel"
            name="pharmacyPhone"
            value={formData.pharmacyPhone}
            onChange={handleChange}
          />
        </FormControl>
      </HStack> */}

      {/* Additional Notes */}
      <FormControl>
        <FormLabel>Additional Notes</FormLabel>
        <Textarea
          name="additionalNotes"
          value={formData.additionalNotes}
          onChange={handleChange}
        />
      </FormControl>
    </>
  );
};

export default ConciergeFormInputs;
